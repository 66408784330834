import { Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import AddHighLights from "./FormModel/AddHighLights";
import { FiEdit } from "react-icons/fi";
import { AiFillDelete } from "react-icons/ai";
import Header from "./Header";
import EditHighLights from "./FormModel/EditHighLights";
import axiosService from "../utils/axios";

const Highlights = () => {
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    link: "",
  });
  const [highlights, setHighlights] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState();

  const fetchPoweringSection = async () => {
    try {
      const response = await axiosService.get("/api/admin/highlights/get");
      if (response.data) {
        setHighlights(response.data);
      }
      // console.log("NEW ReSpONSE DATA ---", response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (id) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this item?"
    );
    if (confirmed) {
      try {
        await axiosService.delete(`/api/admin/highlights/delete/${id}`);
        // Handle the response as needed
        alert("Removed Successfully!");
        fetchPoweringSection();
      } catch (error) {
        console.log(error);
      }
    }
  };
  const handleEdit = (id) => {};

  useEffect(() => {
    fetchPoweringSection();
  }, []);

  // modal
  const handleOpen = () => {
    setOpen(true);
  };
  const handleEditOpen = () => {
    setEditOpen(true);
  };

  // slice text
  function truncateText(text, limit) {
    if (text.length <= limit) {
      return text;
    } else {
      return text.slice(0, limit) + "...";
    }
  }
  function truncateDescription(description, limit) {
    return truncateText(description, limit);
  }
  function truncateLink(linkText, limit) {
    return truncateText(linkText, limit);
  }

  // Indexing
  const handleNext = () => {
    if (selectedIndex < highlights.length - 1) {
      const newInnov = [...highlights];
      let temp = newInnov[selectedIndex];
      newInnov[selectedIndex] = newInnov[selectedIndex + 1];
      newInnov[selectedIndex + 1] = temp;
      setHighlights(newInnov);
      setSelectedIndex(selectedIndex + 1);
    }
  };

  const handlePrev = () => {
    if (selectedIndex > 0) {
      const newInnov = [...highlights];
      let temp = newInnov[selectedIndex];
      newInnov[selectedIndex] = newInnov[selectedIndex - 1];
      newInnov[selectedIndex - 1] = temp;
      setHighlights(newInnov);
      setSelectedIndex(selectedIndex - 1);
    }
  };

  const handleSubmit = async () => {
    try {
      const response = await axiosService.post(`/api/admin/highlight/update`, {
        highlight: highlights,
      });
      alert("Index updated successfully");
    } catch (error) {
      console.error(error);
      // handle error
    }
  };

  return (
    <Box style={{ marginBlock: "3rem" }}>
      <Box style={{ textAlign: "center" }}>
        <Header title="Highlights Slider" />
      </Box>
      <Box>
        <Button
          onClick={handleOpen}
          style={{
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px",
            color: "white",
            backgroundColor: "black",
          }}
        >
          + ADD New Highlights
        </Button>
      </Box>

      <Box style={{ marginTop: "2rem", width: "100%" }}>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "1rem",
            flexWrap: "wrap",
          }}
        >
          {highlights &&
            highlights?.map((data, i) => (
              <Box
                style={{
                  background: "white",
                  color: "black",
                  paddingBlock: "1rem",
                  paddingInline: "1rem",
                  width: "22%",
                  position: "relative",
                  border: selectedIndex === i ? "4px solid #05f605" : "none",
                }}
                key={i}
                onClick={() => setSelectedIndex(i)}
              >
                <h3>{data?.title}</h3>
                <p>{truncateDescription(data?.description, 50)}</p>
                <a
                  href={
                    data?.link.startsWith("http")
                      ? data?.link
                      : `http://${data?.link}`
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {truncateLink(data?.link, 20)}
                </a>
                <Box
                  style={{
                    position: "absolute",
                    display: "flex",
                    gap: "20px",
                    zIndex: "100",
                    paddingRight: "10px",
                    paddingTop: "10px",
                    right: "0px",
                    top: "0px",
                  }}
                >
                  {/* <FiEdit
                    onClick={handleEditOpen}
                    color="#3E4396"
                    size={24}
                    style={{ cursor: "pointer" }}
                  /> */}
                  <AiFillDelete
                    color="red"
                    size={24}
                    onClick={() => handleDelete(data?._id)}
                    style={{ cursor: "pointer" }}
                  />
                </Box>
              </Box>
            ))}
        </Box>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "10px",
            marginTop: "20px",
          }}
        >
          <button
            onClick={handlePrev}
            style={{
              padding: "8px 12px",
              backgroundColor: "#f1f1f1",
              border: "none",
              borderRadius: "4px",
              color: "#333",
              fontWeight: "bold",
              cursor: "pointer",
            }}
          >
            {"<"}
          </button>
          <button
            onClick={handleSubmit}
            style={{
              padding: "8px 12px",
              backgroundColor: "#007bff",
              border: "none",
              borderRadius: "4px",
              color: "#fff",
              fontWeight: "bold",
              cursor: "pointer",
            }}
          >
            Save
          </button>
          <button
            onClick={handleNext}
            style={{
              padding: "8px 12px",
              backgroundColor: "#f1f1f1",
              border: "none",
              borderRadius: "4px",
              color: "#333",
              fontWeight: "bold",
              cursor: "pointer",
            }}
          >
            {">"}
          </button>
        </div>
      </Box>

      <AddHighLights
        open={open}
        setOpen={setOpen}
        formData={formData}
        setFormData={setFormData}
        fetchPoweringSection={fetchPoweringSection}
      />
      <EditHighLights
        editOpen={editOpen}
        setEditOpen={setEditOpen}
        formData={formData}
        setFormData={setFormData}
        fetchPoweringSection={fetchPoweringSection}
      />
    </Box>
  );
};

export default Highlights;
