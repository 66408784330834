import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import {
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import InvovationCard from "../../components/InvovationCard";
import axiosService from "../../utils/axios";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, useTheme } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
  },

  card: {
    display: "flex",
    width: "100%",
  },
  imageContainer: {
    display: "flex",
    flexDirection: "column",
    width: "30%",
    gap: "10px",
    marginRight: "10px",
  },
  image: {
    height: "100px",
    border: "2px solid black",

    objectFit: "cover",
    padding: "1px",
  },
  contentContainer: {
    width: "70%",
  },
  mainImage: {
    height: "210px",
    width: "100%",
    objectFit: "cover",
    border: "2px solid black",
  },
}));
const Innovation = () => {
  const [open, setOpen] = useState(false);
  const [sideUpperImage, setSideUpperImage] = useState(null);
  const [sideLowerImage, setSideLowerImage] = useState(null);
  const [rightImage, setRightImage] = useState(null);
  const [imagePreview1, setImagePreview1] = useState(null);
  const [imagePreview2, setImagePreview2] = useState(null);
  const [imagePreview3, setImagePreview3] = useState(null);
  const [data, setData] = useState([]);
  const handleFileSelection = (event, setImage, setImagePreview) => {
    const file = event.target.files[0];
    setImage(file);

    // Create a file reader object
    const reader = new FileReader();

    // Define the onload function to set the preview image
    reader.onload = (e) => {
      const imagePreview = e.target.result;
      setImagePreview(imagePreview);
    };

    // Read the selected image file as data URL
    reader.readAsDataURL(file);
  };

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("sideUpperImage", sideUpperImage);
      formData.append("sideLowerImage", sideLowerImage);
      formData.append("rightImage", rightImage);
      const response = await axiosService.post(
        "/api/admin/innovation",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      alert("Successfully uploaded");
      setSideUpperImage(null);
      setRightImage(null);
      setSideLowerImage(null);
      setImagePreview1(null);
      setImagePreview2(null);
      setImagePreview3(null);
      setData([...data, response.data]);

      handleClose();
      console.log("File uploaded successfully:", response.data);
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const fetchTeams = async () => {
    try {
      const response = await axiosService.get("/api/admin/innovation/get");
      setData(response.data);

      console.log("ReSpONSE DATA", response.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchTeams();
  }, []);
  const classes = useStyles();

  // form
  const [formData, setFormData] = useState({
    title: "",
    description: "",
  });
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const fetchPoweringSection = async () => {
    try {
      const response = await axiosService.get(
        "/api/admin/innovation-section/get"
      );
      if (response.data) {
        setFormData(response.data);
      }
      // console.log("ReSpONSE DATA ---", response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchPoweringSection();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      title: formData.title,
      description: formData.description,
    };
    console.log(payload);
    try {
      const response = await axiosService.put(
        "/api/admin/innovation-section",
        payload
      );
      console.log(response.data);
      alert("Submission Successfull");

      // do something after successful submission
    } catch (error) {
      console.error(error);
      alert("Something went wrong");
      // handle error
    }
  };

  return (
    <>
      <Container>
      <div
        style={{ overflowY: "auto", maxHeight: "85vh", overflowX: "hidden" }}
      >
        <Box>
          <Box style={{ textAlign: "center" }}>
            <Header title="Innovation" />
          </Box>
          <Box>
            <Box display="flex" flexDirection="column">
              <form
                onSubmit={handleSubmit}
                style={{
                  color: "#FCFCFC",
                  background: "#FCFCFC",
                  paddingInline: "3rem",
                  paddingBlock: "3rem",
                }}
              >
                <TextField
                  name="title"
                  label="Title"
                  value={formData.title}
                  onChange={handleChange}
                  fullWidth
                />
                <TextField
                  name="description"
                  label="Description"
                  value={formData.description}
                  onChange={handleChange}
                  fullWidth
                  required
                />
                <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{
                      marginTop: "1rem",
                      // marginBottom: "1rem",
                      textAlign: "center",
                    }}
                  >
                    Submit
                  </Button>
                </Box>
              </form>
            </Box>
          </Box>
          <Button
            style={{
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
              color: "white",
              marginLeft: "20px",
              marginTop: "20px",
              marginBottom: "20px",
              backgroundColor: "black",
            }}
            onClick={handleOpen}
          >
            + ADD New
          </Button>
        </Box>
        <Box>
          <InvovationCard data={data} setData={setData} />
        </Box>
        </div>
      </Container>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <p style={{ margin: "0px", padding: "0px" }}> Select an Images</p>
          {imagePreview1 || imagePreview2 || imagePreview3 ? (
            <p
              style={{
                margin: "0px",
                padding: "0px",
                fontSize: "10px",
                color: "grey",
              }}
            >
              {" "}
              Images Preview
            </p>
          ) : (
            ""
          )}
        </DialogTitle>
        <DialogContent>
          {/* imge prive  */}
          <div className={classes.root}>
            <Card className={classes.card}>
              <div className={classes.imageContainer}>
                {imagePreview1 && (
                  <img
                    className={classes.image}
                    src={imagePreview1}
                    alt="Card Preview"
                    required
                  />
                )}
                {imagePreview2 && (
                  <img
                    className={classes.image}
                    src={imagePreview2}
                    alt="Card Preview"
                  />
                )}
              </div>
              <div className={classes.contentContainer}>
                {imagePreview3 && (
                  <img
                    className={classes.mainImage}
                    src={imagePreview3}
                    alt="Card Preview"
                  />
                )}
              </div>
            </Card>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div style={{ marginBottom: "40px" }}>
              <label htmlFor="">Upper Left Image</label> <br />
              <input
                type="file"
                name="sideUpperImage"
                onChange={(event) =>
                  handleFileSelection(
                    event,
                    setSideUpperImage,
                    setImagePreview1
                  )
                }
              />
              {imagePreview1 && (
                <img
                  src={imagePreview1}
                  alt="Card Preview"
                  style={{ width: "50px", height: "auto" }}
                />
              )}
            </div>
            <div style={{ marginBottom: "40px" }}>
              <label htmlFor="">Down Left Image</label> <br />
              <input
                type="file"
                name="sideLowerImage"
                onChange={(event) =>
                  handleFileSelection(
                    event,
                    setSideLowerImage,
                    setImagePreview2
                  )
                }
              />
              {imagePreview2 && (
                <img
                  src={imagePreview2}
                  alt="Card Preview"
                  style={{ width: "50px", height: "auto" }}
                />
              )}
            </div>
            <div>
              <label htmlFor="">Right Main Image </label>
              <br />
              <input
                type="file"
                name="rightImage"
                onChange={(event) =>
                  handleFileSelection(event, setRightImage, setImagePreview3)
                }
              />
              {imagePreview3 && (
                <img
                  src={imagePreview3}
                  alt="Card Preview"
                  style={{ width: "50px", height: "auto" }}
                />
              )}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave}>Submit</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Innovation;
