import { Box, Button, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useEffect, useState } from "react";
import axiosService from "../../utils/axios";
import { useNavigate } from "react-router-dom";

const Contact = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [joins, setJoins] = useState([]);
  useEffect(() => {
    const fetchJoins = async () => {
      const response = await axiosService.get("/api/admin/contacts/get");
      const rowsWithIds = response.data.map((row, i) => ({
        ...row,
        id: i + 1,
      }));
      setJoins(rowsWithIds);
      // console.log(rowsWithIds);
    };
    fetchJoins();
  }, []);

  const handleDelete = async (id) => {
    console.log(id);
    const confirmed = window.confirm(
      "Are you sure you want to delete this item?"
    );
    if (confirmed) {
      try {
        // Send a DELETE request to the API endpoint
        await axiosService.delete(`/api/admin/contacts/delete/${id}`);

        // Remove the deleted row from the state
        setJoins((prevJoins) => prevJoins.filter((join) => join._id !== id));
      } catch (error) {
        console.error(error);
      }
    }
  };
  useEffect(() => {}, [joins]);

  const columns = [
    { field: "id", headerName: "ID" },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      cellClassName: "name-column--cell",
      width: 50,
    },
    {
      field: "org",
      headerName: "Organization",
      flex: 1,
      width: 100,
    },
    {
      field: "email",
      headerName: "Email",
      headerAlign: "left",
      align: "left",
      width: 280,
    },
    {
      field: "message",
      headerName: "Message",
      flex: 1,
      // width: 500,
      //   renderCell: (params) => (
      //     <a
      //       style={{ color: "white", textDecoration: "none" }}
      //       href={params.value}
      //       target="_blank"
      //       rel="noopener noreferrer"
      //     >
      //       {params.value}
      //     </a>
      //   ),
    },

    {
      field: "delete",
      headerName: "Action",
      sortable: false,
      // width: 100,
      renderCell: (params) => (
        <Button
          className="delete-button"
          onClick={() => handleDelete(params.row._id)}
        >
          Delete
        </Button>
      ),
    },
  ];
  // newcomment
  useEffect(() => {
    const enraptureUser = JSON.parse(localStorage.getItem("enrapture-user"));

    if (!enraptureUser) {
      navigate("/login");
    }
    console.log("checking");
  }, []);
  const navigate = useNavigate();
  return (
    <Box style={{ paddingInline: "20px" }}>
      <Box style={{ textAlign: "center" }}>
        <Header title="TEAM" />
      </Box>
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .delete-button": {
            backgroundColor: "red",
            color: "white",
          },
        }}
      >
        <DataGrid rows={joins} columns={columns} />
      </Box>
    </Box>
  );
};

export default Contact;
