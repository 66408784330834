import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Input,
} from "@material-ui/core";
import axiosService from "../../utils/axios";
const AddNew = ({
  open,
  handleOpen,
  setOpen,
  formData,
  setFormData,
  teams,
  setTeams,
  fetchTeams,
}) => {
  const handleClose = () => {
    setOpen(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFileChange = (event) => {
    setFormData((prevState) => ({
      ...prevState,
      image: event.target.files[0],
    }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("image", formData.image);
    data.append("fullname", formData.fullname);
    data.append("title", formData.title);
    data.append("description", formData.description);
    try {
      const response = await axiosService.post("/api/admin/team", data);
      console.log(response.data);
      setTeams([...teams, response.data]);
      fetchTeams();
      alert("Submission Successfull");
      setFormData({
        image: null,
        fullname: "",
        title: "",
        description: "",
      });
      handleClose();
      // do something after successful submission
    } catch (error) {
      console.error(error);
      // handle error
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle style={{ textAlign: "center" }}>Add New Leader</DialogTitle>
      <DialogContent>
        <form onSubmit={handleFormSubmit}>
          <Input
            type="file"
            name="image"
            onChange={handleFileChange}
            style={{ marginTop: "10px" }}
          />
          <TextField
            name="fullname"
            label="Full Name"
            fullWidth
            required
            value={formData.fullname}
            onChange={handleInputChange}
          />
          <TextField
            name="title"
            label="Title"
            fullWidth
            required
            value={formData.title}
            onChange={handleInputChange}
          />
          <TextField
            name="description"
            label="Description"
            multiline
            rows={4}
            fullWidth
            value={formData.description}
            onChange={handleInputChange}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleFormSubmit} color="primary">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddNew;
