import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Input,
} from "@material-ui/core";
// import { AiFillDelete } from "react-icons/ai";
// import { FcSynchronize } from "react-icons/fc";
// import { BsArrowRepeat } from "react-icons/bs";
import axiosService from "../../utils/axios";
const EditData = ({
  open,
  handleOpen,
  setOpen,
  formData,
  setFormData,
  fetchTeams,
  currentUserID,
}) => {
  const handleClose = () => {
    setOpen(false);
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFileChange = (event) => {
    setFormData((prevState) => ({
      ...prevState,
      image: event.target.files[0],
    }));
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    console.log(currentUserID);

    console.log(formData);
    const data = {
      fullname: formData.fullname,
      title: formData.title,
      description: formData.description,
      image: formData.image,
    };
    console.log(data);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    try {
      const response = await axiosService.patch(
        `/api/admin/team/edit/${currentUserID}`,
        data,
        config
      );
      console.log(response.data);
      setOpen(false);
      fetchTeams();
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteImage = async () => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this image?"
    );
    if (!confirmed) {
      return;
    }
    try {
      setFormData({
        image: null,
      });
      const response = await axiosService.put(
        `/api/admin/team/image/${currentUserID}`
      );
      console.log(response.data);
      // setOpen(false);
      fetchTeams();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle style={{ textAlign: "center" }}>Add New Leader</DialogTitle>
      <DialogContent>
        <form encType="multipart/form-data">
          {formData.image && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img
                src={`http://localhost:5000/uploads/${formData.image}`}
                alt="Team Image"
                style={{ height: "150px" }}
              />
              {/* <AiFillDelete
                color="red"
                size={24}
                onClick={handleDeleteImage}
                style={{ cursor: "pointer" }}
              /> */}
              {/* <p onClick={handleImageRemove}>remove</p> */}
              {/* <RiDeleteBin5Line
                className="text-[red] cursor-pointer ml-[10px]"
                size={25}
                onClick={handleDeleteImage}
              /> */}
            </div>
          )}

          <>
            <label
              htmlFor="image"
              className="block text-gray-700 font-bold mb-2"
            >
              Change/Update Image:
            </label>
            {/* <FcSynchronize size={50} /> */}
            <Input
              type="file"
              name="image"
              onChange={handleFileChange}
              style={{ marginTop: "10px" }}
            />
          </>

          <TextField
            name="fullname"
            label="Full Name"
            fullWidth
            required
            value={formData.fullname}
            onChange={handleInputChange}
          />
          <TextField
            name="title"
            label="Title"
            fullWidth
            required
            value={formData.title}
            onChange={handleInputChange}
          />
          <TextField
            name="description"
            label="Description"
            multiline
            rows={4}
            fullWidth
            value={formData.description}
            onChange={handleInputChange}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleFormSubmit} color="primary">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditData;
