import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { TextField, useTheme, Button } from "@material-ui/core";
import Header from "./Header";
import axiosService from "../utils/axios";

const Expertise = () => {
  const theme = useTheme();

  const [formData, setFormData] = useState({
    title: "",
    desc1: "",
    desc2: "",
    desc3: "",
    desc4: "",
    desc5: "",
    desc6: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      title: formData.title,
      desc1: formData.desc1,
      desc2: formData.desc2,
      desc3: formData.desc3,
      desc4: formData.desc4,
      desc5: formData.desc5,
      desc6: formData.desc6,
    };

    try {
      const response = await axiosService.put("/api/admin/expertise", payload);
      console.log(response.data);
      // Handle the response data or perform any further actions as needed

      // Reset the form data
      alert("Updated Successfully");
    } catch (error) {
      console.error(error);
      // Handle the error
    }
  };

  const fetchExpertiseSection = async () => {
    try {
      const response = await axiosService.get("/api/admin/expertise/get");
      if (response.data) {
        setFormData(response.data);
      }
      // console.log("ReSpONSE DATA ---", response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchExpertiseSection();
  }, []);
  return (
    <Box>
      <Box style={{ textAlign: "center" }}>
        <Header title="Our Expertise:" />
      </Box>
      <Box display="flex" flexDirection="column">
        <form
          onSubmit={handleSubmit}
          style={{
            color: "#FCFCFC",
            background: "#FCFCFC",
            paddingInline: "3rem",
            paddingBlock: "2rem",
          }}
        >
          <TextField
            name="title"
            label="Our Expertise Title"
            value={formData?.title}
            onChange={handleChange}
            fullWidth
            InputLabelProps={{
              style: {
                color: theme.palette.mode === "dark" ? "white" : undefined,
              },
            }}
            InputProps={{
              style: {
                color: theme.palette.mode === "dark" ? "white" : undefined,
              },
            }}
          />
          <TextField
            name="desc1"
            label="Metaverse Corporate "
            value={formData?.desc1}
            onChange={handleChange}
            fullWidth
            InputLabelProps={{
              style: {
                color: theme.palette.mode === "dark" ? "white" : undefined,
              },
            }}
            InputProps={{
              style: {
                color: theme.palette.mode === "dark" ? "white" : undefined,
              },
            }}
          />
          <TextField
            name="desc2"
            label="Immersive Tech"
            value={formData?.desc2}
            onChange={handleChange}
            fullWidth
            InputLabelProps={{
              style: {
                color: theme.palette.mode === "dark" ? "white" : undefined,
              },
            }}
            InputProps={{
              style: {
                color: theme.palette.mode === "dark" ? "white" : undefined,
              },
            }}
          />
          <TextField
            name="desc3"
            label="Full Stack Software"
            value={formData?.desc3}
            onChange={handleChange}
            fullWidth
            InputLabelProps={{
              style: {
                color: theme.palette.mode === "dark" ? "white" : undefined,
              },
            }}
            InputProps={{
              style: {
                color: theme.palette.mode === "dark" ? "white" : undefined,
              },
            }}
          />
          <TextField
            name="desc4"
            label="3D Modelling"
            value={formData?.desc4}
            onChange={handleChange}
            fullWidth
            InputLabelProps={{
              style: {
                color: theme.palette.mode === "dark" ? "white" : undefined,
              },
            }}
            InputProps={{
              style: {
                color: theme.palette.mode === "dark" ? "white" : undefined,
              },
            }}
          />
          <TextField
            name="desc5"
            label="Blockchain Development"
            value={formData?.desc5}
            onChange={handleChange}
            fullWidth
            InputLabelProps={{
              style: {
                color: theme.palette.mode === "dark" ? "white" : undefined,
              },
            }}
            InputProps={{
              style: {
                color: theme.palette.mode === "dark" ? "white" : undefined,
              },
            }}
          />
          <TextField
            name="desc6"
            label="Metaverse & Web3 Strategy Consultation"
            value={formData?.desc6}
            onChange={handleChange}
            fullWidth
            InputLabelProps={{
              style: {
                color: theme.palette.mode === "dark" ? "white" : undefined,
              },
            }}
            InputProps={{
              style: {
                color: theme.palette.mode === "dark" ? "white" : undefined,
              },
            }}
          />
          <Box style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{
                marginTop: "1rem",
                marginBottom: "1rem",
                textAlign: "center",
              }}
            >
              Submit
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default Expertise;
