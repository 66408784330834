import { Box, Button, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import axiosService from "../../utils/axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Subscribed = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [subscribers, setSubscribers] = useState([]);

  const handleDelete = async (id) => {
    console.log(id);
    const confirmed = window.confirm(
      "Are you sure you want to delete this item?"
    );
    if (confirmed) {
      try {
        // Send a DELETE request to the API endpoint
        await axiosService.delete(`/api/admin/subscriber/delete/${id}`);

        // Remove the deleted row from the state
        setSubscribers((prevJoins) =>
          prevJoins.filter((sub) => sub._id !== id)
        );
      } catch (error) {
        console.error(error);
      }
    }
  };
  const columns = [
    { field: "id", headerName: "ID" },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    ,
    {
      field: "delete",
      headerName: "Action",
      sortable: false,
      // width: 100,
      renderCell: (params) => (
        <Button
          className="delete-button"
          onClick={() => handleDelete(params.row._id)}
        >
          Delete
        </Button>
      ),
    },
  ];
  useEffect(() => {
    const fetchJoins = async () => {
      const response = await axiosService.get("/api/admin/subscriber/get");
      const rowsWithIds = response.data.map((row, i) => ({
        ...row,
        id: i + 1,
      }));
      setSubscribers(rowsWithIds);
      console.log("SUBS", rowsWithIds);
    };
    fetchJoins();
  }, []);
  const navigate = useNavigate();
  useEffect(() => {
    const enraptureUser = JSON.parse(localStorage.getItem("enrapture-user"));

    console.log(!enraptureUser);
    if (!enraptureUser) {
      navigate("/login");
      console.log("navigating");
    }
    console.log("checking");
  }, []);

  return (
    <Box style={{ paddingInline: "20px" }}>
      <Box style={{ textAlign: "center" }}>
        <Header title="Subscribed User" />
      </Box>
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .delete-button": {
            backgroundColor: "red",
            color: "white",
          },
        }}
      >
        <DataGrid rows={subscribers} columns={columns} />
      </Box>
    </Box>
  );
};

export default Subscribed;
