import { useState } from "react";
import { Routes, Route } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";

import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Teams from "./scenes/dashboard/Teams";
import Leadership from "./scenes/dashboard/Leadership";
import Login from './scenes/auth/Login';
import { AuthProvider } from "./context/authContext";
import Subscribed from "./scenes/dashboard/SubScribedUser";
import AdminSettings from "./scenes/auth/AdminSetting";
import Innovation from "./scenes/dashboard/Innovation";
import Highlights from "./scenes/dashboard/DigitalPowering";
import Insights from "./scenes/dashboard/Insights";
import Contact from './scenes/dashboard/Contact';
import Legal from "./scenes/dashboard/Legal";
// Layout component for the main app layout
function MainLayout({ children }) {
  const [isSidebar, setIsSidebar] = useState(true);
  return (
    <>
      <Sidebar isSidebar={isSidebar} />
      <main className="content">
        <Topbar setIsSidebar={setIsSidebar} />
        {children}
      </main>
    </>
  );
}

// Layout component for the login page layout
function LoginLayout({ children }) {
  return <>
    <div className="login-layout" style={{ width: '100%', display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', background: '#141B2D' }}>{children}</div>
  </>
}

function App() {
  const [theme, colorMode] = useMode();

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <AuthProvider>
            <Routes>
              <Route path="/" element={<MainLayout><Subscribed /></MainLayout>} />
              <Route path="/SubscribedUser" element={<MainLayout> <Subscribed /> </MainLayout>} />
              <Route path="/Teams" element={<MainLayout><Teams /></MainLayout>} />
              <Route path="/leadership" element={<MainLayout><Leadership /></MainLayout>} />
              <Route path="/innovation" element={<MainLayout><Innovation /></MainLayout>} />
              <Route path="/highlights" element={<MainLayout><Highlights /></MainLayout>} />
              <Route path="/insights" element={<MainLayout><Insights /></MainLayout>} />
              <Route path="/legal" element={<MainLayout><Legal/></MainLayout>} />
              <Route path="/setting" element={<MainLayout><AdminSettings /></MainLayout>} />
              <Route path="/contact" element={<MainLayout><Contact /></MainLayout>} />
              <Route path="/login/*" element={<LoginLayout>
                <Routes>
                  <Route path="/" element={<Login />} />
                </Routes></LoginLayout>} />
            </Routes>
          </AuthProvider>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
