import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTheme, Button } from "@material-ui/core";
import Header from "./Header";
import axiosService from "../utils/axios";
import { imageURL } from "../utils/url";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import { Grid } from "@material-ui/core";
import { AiFillDelete } from "react-icons/ai";
const CompanySlider = () => {
  const theme = useTheme();
  const [formData, setFormData] = useState({
    image: null,
  });
  const [images, setImages] = useState([]);

  const handleChange = (event) => {
    const { name, value, files } = event.target;
    if (name === "image") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: files[0],
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      image: formData.image,
    };
    console.log("payload", payload);

    try {
      const response = await axiosService.post(
        "/api/admin/virtual-image",
        payload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(response.data);

      alert("Updated Successfully");
      setImages([...images, response.data])
      // fetchVirtualWorldImageSection();
      setFormData({
        image: null,
      });
    } catch (error) {
      console.error(error);
      // Handle the error
    }
  };

  const fetchVirtualWorldImageSection = async () => {
    try {
      const response = await axiosService.get("/api/admin/virtual-image/get");
      if (response.data) {
        setImages(response.data);
      }

      console.log("ReSpONSE DATA ---", response.data);
      
    } catch (error) {
      console.log(error);
    }
  };
  const handleDelete = async (id) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this item?"
    );
    if (confirmed) {
      try {
        await axiosService.delete(`/api/admin/virtual-image/delete/${id}`);
        // Handle the response as needed
        setImages((prevImage) => prevImage.filter((item) => item._id !== id));
        alert("Removed Successfully!");
      } catch (error) {
        console.log(error);
      }
    }
  };
  useEffect(() => {
    fetchVirtualWorldImageSection();
  }, []);

  return (
    <Box style={{ marginBlock: "2rem" }}>
      <Box style={{ textAlign: "center" }}>
        <Header title="Virtual world experience Slider Image" />
      </Box>
      <Box display="flex" flexDirection="column">
        <form
          onSubmit={handleSubmit}
          style={{
            color: "#FCFCFC",
            background: "#FCFCFC",
            paddingInline: "2rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              // alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            {formData.image && (
              <img
                src={URL.createObjectURL(formData?.image)}
                alt="Selected Image"
                style={{
                  // width: "100px",
                  height: "100px",
                  marginBottom: "1rem",
                }}
              />
            )}
            <input
              type="file"
              name="image"
              accept="image/*"
              onChange={handleChange}
              style={{
                marginBlock: "1rem",
              }}
            />
          </div>

          <Box style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{
                textAlign: "center",
                marginBlock: "1rem",
              }}
            >
              Submit
            </Button>
          </Box>
        </form>
      </Box>
      <h1> Virtual World Experience Images</h1>
      <Box>
        <div
          style={{
            // color: "#FCFCFC",
            // background: "#FCFCFC",
            paddingInline: "2rem",
            display: "flex",
            width: "100%",
            // alignItems: "center",
            // justifyContent: "center",
          }}
        >
          <Box style={{ display: "flex", flexWrap: "wrap", gap: "20px"}}>
            {images?.map((e) => (
              <Box style={{ position: "relative" }}>
                <Card>
                  <CardActionArea>
                    <CardContent>
                      <img
                        src={imageURL + "/uploads/" + e.image}
                        alt="Selected Image"
                        style={{
                          width: "100px",
                          height: "100px",
                          marginBottom: "1rem",
                        }}
                      />
                    </CardContent>
                  </CardActionArea>
                </Card>
                <AiFillDelete
                  color="red"
                  size={24}
                  onClick={() => handleDelete(e?._id)}
                  style={{
                    cursor: "pointer",
                    position: "absolute",
                    top: "0",
                    right: "2",
                  }}
                />
              </Box>
            ))}
          </Box>
        </div>
      </Box>
    </Box>
  );
};

export default CompanySlider;
