import React, { useEffect, useState } from "react";
import { Box, Container, Button } from "@mui/material";
import { Input, TextField, useTheme } from "@material-ui/core";
import Header from "../../components/Header";
import axiosService from "./../../utils/axios";
import { imageURL } from "./../../utils/url";

const Insights = () => {
  const [formData, setFormData] = useState({
    heading: "",
    description: "",
    blogImage1: "",
    blogTitle1: "",
    blogDescription1: "",
    blogLink1: "",
    blogImage2: "",
    blogTitle2: "",
    blogDescription2: "",
    blogLink2: "",
    blogImage3: "",
    blogTitle3: "",
    blogDescription3: "",
    blogLink3: "",
    blogImage4: "",
    blogTitle4: "",
    blogDescription4: "",
    blogLink4: "",
  });

  const [previewImage, setPreviewImage] = useState("");

  const handleChange = (event) => {
    const { name, value, type } = event.target;

    if (type === "file") {
      const file = event.target.files[0];
      setFormData((prevData) => ({
        ...prevData,
        [name]: file,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const fetchPoweringSection = async () => {
    try {
      const response = await axiosService.get("/api/admin/insights/get");
      if (response.data) {
        setFormData(response.data);
      }
      // console.log("ReSpONSE DATA ---", response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchPoweringSection();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      heading: formData.heading,
      description: formData.description,
      blogImage1: formData.blogImage1,
      blogTitle1: formData.blogTitle1,
      blogDescription1: formData.blogDescription1,
      blogLink1: formData.blogLink1,
      blogImage2: formData.blogImage2,
      blogTitle2: formData.blogTitle2,
      blogDescription2: formData.blogDescription2,
      blogLink2: formData.blogLink2,
      blogImage3: formData.blogImage3,
      blogTitle3: formData.blogTitle3,
      blogDescription3: formData.blogDescription3,
      blogLink3: formData.blogLink3,
      blogImage4: formData.blogImage4,
      blogTitle4: formData.blogTitle4,
      blogDescription4: formData.blogDescription4,
      blogLink4: formData.blogLink4,
    };
    // console.log(payloadR);
    try {
      const response = await axiosService.put("/api/admin/insights", payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log(response.data);
      alert("Submission Successful");

      // do something after successful submission
    } catch (error) {
      console.error(error);
      alert("Something went wrong");
      // handle error
    }
  };

  return (
    <Container>
      <Box paddingBlock="3rem">
        <Box textAlign="center">
          <Header title="Insights" />
        </Box>
        <Box
          style={{ overflowY: "auto", maxHeight: "70vh", overflowX: "hidden" }}
        >
          <form
            onSubmit={handleSubmit}
            style={{
              color: "#FCFCFC",
              background: "#FCFCFC",
              paddingInline: "3rem",
              paddingBlock: "3rem",
            }}
          >
            <Box style={{ paddingBlock: "1rem" }}>
              <Box style={{ textAlign: "center" }}>
                <Header title="Main Heading & Dec" />
              </Box>
              <TextField
                name="heading"
                label="Heading"
                value={formData.heading}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
              {/* <TextField
                name="description"
                label="Description"
                value={formData.description}
                onChange={handleChange}
                fullWidth
                margin="normal"
                multiline
                rows={4}
              /> */}
            </Box>
            <Box>
              {/* Blog 1  */}
              <Box style={{ textAlign: "center" }}>
                <Header title="Blog 1" />
              </Box>
              {/* form field blog 1  */}
              <Box display="flex" alignItems="center" justifyContent="center">
                <TextField
                  type="file"
                  name="blogImage1"
                  label="Blog Image 1"
                  onChange={handleChange}
                  margin="normal"
                  inputProps={{ accept: "image/*" }}
                />
                {formData.blogImage1 && (
                  <img
                    src={`${imageURL}/uploads/${formData.blogImage1}`}
                    alt="Preview"
                    style={{ marginLeft: "20px", maxHeight: "100px" }}
                  />
                )}
              </Box>
              <TextField
                name="blogTitle1"
                label="Blog Title 1"
                value={formData.blogTitle1}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
              <TextField
                name="blogDescription1"
                label="Blog Description 1"
                value={formData.blogDescription1}
                onChange={handleChange}
                fullWidth
                margin="normal"
                multiline
                rows={4}
              />
              <TextField
                name="blogLink1"
                label="Blog Link 1"
                value={formData.blogLink1}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </Box>

            {/* Blog 2  */}
            <Box style={{ textAlign: "center", marginTop: "1rem" }}>
              <Header title="Blog 2" />
            </Box>
            <Box>
              <Box>
                {/* form field blog 2 */}

                <Box display="flex" alignItems="center" justifyContent="center">
                  <TextField
                    type="file"
                    name="blogImage2"
                    label="Blog Image 1"
                    onChange={handleChange}
                    margin="normal"
                    inputProps={{ accept: "image/*" }}
                  />
                  {formData.blogImage2 && (
                    <img
                      imageURL
                      src={`${imageURL}/uploads/${formData.blogImage2}`}
                      alt="Preview"
                      style={{ marginLeft: "20px", maxHeight: "100px" }}
                    />
                  )}
                </Box>
                <TextField
                  name="blogTitle2"
                  label="Blog Title 2"
                  value={formData.blogTitle2}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  name="blogDescription2"
                  label="Blog Description 2"
                  value={formData.blogDescription2}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  multiline
                  rows={4}
                />
                <TextField
                  name="blogLink2"
                  label="Blog Link 2"
                  value={formData.blogLink2}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                />
              </Box>
            </Box>

            {/* Blog 3  */}
            <Box style={{ textAlign: "center", marginTop: "1rem" }}>
              <Header title="Blog 3" />
            </Box>
            <Box>
              {/* form field blog 3 */}
              <Box display="flex" alignItems="center" justifyContent="center">
                <TextField
                  type="file"
                  name="blogImage3"
                  label="Blog Image 1"
                  onChange={handleChange}
                  margin="normal"
                  inputProps={{ accept: "image/*" }}
                />
                {formData.blogImage3 && (
                  <img
                    imageURL
                    src={`${imageURL}/uploads/${formData.blogImage3}`}
                    alt="Preview"
                    style={{ marginLeft: "20px", maxHeight: "100px" }}
                  />
                )}
              </Box>
              <TextField
                name="blogTitle3"
                label="Blog Title 3"
                value={formData.blogTitle3}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
              <TextField
                name="blogDescription3"
                label="Blog Description 3"
                value={formData.blogDescription3}
                onChange={handleChange}
                fullWidth
                margin="normal"
                multiline
                rows={4}
              />
              <TextField
                name="blogLink3"
                label="Blog Link 3"
                value={formData.blogLink3}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </Box>
            {/* Blog 4  */}
            <Box style={{ textAlign: "center", marginTop: "1rem" }}>
              <Header title="Blog 4" />
            </Box>

            <Box style={{ paddingBottom: "1rem" }}>
              {/* form fields blog 4*/}

              <Box display="flex" alignItems="center" justifyContent="center">
                <TextField
                  type="file"
                  name="blogImage4"
                  label="Blog Image 1"
                  onChange={handleChange}
                  margin="normal"
                  inputProps={{ accept: "image/*" }}
                />
                {formData.blogImage4 && (
                  <img
                    imageURL
                    src={`${imageURL}/uploads/${formData.blogImage4}`}
                    alt="Preview"
                    style={{ marginLeft: "20px", maxHeight: "100px" }}
                  />
                )}
              </Box>
              <TextField
                name="blogTitle4"
                label="Blog Title 4"
                value={formData.blogTitle4}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
              <TextField
                name="blogDescription4"
                label="Blog Description 4"
                value={formData.blogDescription4}
                onChange={handleChange}
                fullWidth
                margin="normal"
                multiline
                rows={4}
              />
              <TextField
                name="blogLink4"
                label="Blog Link 4"
                value={formData.blogLink4}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </Box>
            {/* End of blog 1 fields */}
            {/* Repeat the above block for blog 2 to blog 4 */}
            {/* ... */}

            <Button
              variant="contained"
              color="primary"
              type="submit"
              style={{ float: "right" }}
            >
              Submit
            </Button>
          </form>
        </Box>
      </Box>
    </Container>
  );
};

export default Insights;
