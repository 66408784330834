import React, { useState, useEffect } from "react";
import LeadershipCard from "../../components/LeadershipCard";
import Header from "../../components/Header";
import { Button, Box } from "@material-ui/core";
// import { styled } from "@material-ui/core/styles";
import AddNew from "../../components/FormModel/AddNew";
import axiosService from "../../utils/axios";
import Container from "@material-ui/core/Container";
import { TextField, useTheme } from "@material-ui/core";
import { tokens } from "../../theme";

const Leadership = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    image: null,
    fullname: "",
    title: "",
    description: "",
  });
  const [teams, setTeams] = useState([]);

  const [leadership, setLeadership] = useState({
    title: "",
    description: "",
    quote: "",
    joinTheTeam: "",
  });

  const fetchLeaderSection = async () => {
    try {
      const response = await axiosService.get("/api/admin/team-section/get");
      if (response.data) {
        setLeadership(response.data);
      }
      // console.log("ReSpONSE DATA ---", response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      title: leadership.title,
      description: leadership.description,
      quote: leadership.quote,
      joinTheTeam: leadership.joinTheTeam,
    };
    console.log(payload);
    try {
      const response = await axiosService.put(
        "/api/admin/team-section",
        payload
      );
      console.log(response.data);
      alert("Submission Successfull");
    } catch (error) {
      console.error(error);
      alert("Something went wrong");
      // handle error
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    fetchTeams();
    fetchLeaderSection();
  }, []);

  const handleLeadershipChange = (event) => {
    const { name, value } = event.target;
    setLeadership((prevLeadership) => ({
      ...prevLeadership,
      [name]: value,
    }));
  };
  const fetchTeams = async () => {
    try {
      const response = await axiosService.get("/api/admin/team/get");
      setTeams(response.data);
      console.log("ReSpONSE DATA TEAMMMM", response.data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Container>
        <Box style={{ textAlign: "center" }}>
          <Header title="Leadership" />
        </Box>
        <Box
          style={{
            overflowY: "auto",
            maxHeight: "80vh",
            overflowX: "hidden",
            paddingBlock: "1rem",
          }}
        >
          <Box>
            <Box display="flex" flexDirection="column">
              <form
                onSubmit={handleSubmit}
                style={{
                  color: "#FCFCFC",
                  background: "#FCFCFC",
                  paddingInline: "3rem",
                  paddingBlock: "3rem",
                }}
              >
                <TextField
                  name="title"
                  label="Title"
                  value={leadership?.title}
                  onChange={(event) =>
                    setLeadership((prevLeadership) => ({
                      ...prevLeadership,
                      title: event.target.value,
                    }))
                  }
                  fullWidth
                  InputLabelProps={{
                    style: {
                      color:
                        theme.palette.mode === "dark" ? "white" : undefined,
                    },
                  }}
                  InputProps={{
                    style: {
                      color:
                        theme.palette.mode === "dark" ? "white" : undefined,
                    },
                  }}
                />
                <TextField
                  name="description"
                  label="Description"
                  value={leadership?.description}
                  onChange={handleLeadershipChange}
                  fullWidth
                  required
                  InputLabelProps={{
                    style: {
                      color:
                        theme.palette.mode === "dark" ? "white" : undefined,
                    },
                  }}
                  InputProps={{
                    style: {
                      color:
                        theme.palette.mode === "dark" ? "white" : undefined,
                    },
                  }}
                />
                <TextField
                  name="quote"
                  label="Quote"
                  value={leadership?.quote}
                  onChange={handleLeadershipChange}
                  fullWidth
                  required
                  InputLabelProps={{
                    style: {
                      color:
                        theme.palette.mode === "dark" ? "white" : undefined,
                    },
                  }}
                  InputProps={{
                    style: {
                      color:
                        theme.palette.mode === "dark" ? "white" : undefined,
                    },
                  }}
                />
                <TextField
                  name="joinTheTeam"
                  label="Join the Team Heading"
                  value={leadership?.joinTheTeam}
                  onChange={handleLeadershipChange}
                  fullWidth
                  required
                  InputLabelProps={{
                    style: {
                      color:
                        theme.palette.mode === "dark" ? "white" : undefined,
                    },
                  }}
                  InputProps={{
                    style: {
                      color:
                        theme.palette.mode === "dark" ? "white" : undefined,
                    },
                  }}
                />
                <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{
                      marginTop: "1rem",
                      marginBottom: "1rem",
                      textAlign: "center",
                    }}
                  >
                    Submit
                  </Button>
                </Box>
              </form>
            </Box>
          </Box>
          <Box>
            <Button
              onClick={handleOpen}
              style={{
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
                color: "white",
                marginTop: "10px",
                backgroundColor: "black",
              }}
            >
              + ADD New Leader
            </Button>
          </Box>
          <Box>
            <LeadershipCard
              teams={teams}
              setTeams={setTeams}
              fetchTeams={fetchTeams}
            />
            <AddNew
              open={open}
              handleOpen={handleOpen}
              setOpen={setOpen}
              formData={formData}
              setFormData={setFormData}
              teams={teams}
              setTeams={setTeams}
              fetchTeams={fetchTeams}
            />
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default Leadership;
