import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
} from "@material-ui/core";
import axiosService from "../../utils/axios";

const AddHighLights = ({ open, setOpen, fetchPoweringSection }) => {
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    link: "",
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      title: formData.title,
      description: formData.description,
      link: formData.link,
    };
    console.log(payload);
    try {
      const response = await axiosService.post(
        "/api/admin/highlights",
        payload
      );
      alert("Submission Successfull");
      setFormData({
        title: "",
        description: "",
        link: "",
      });
      fetchPoweringSection();
      handleClose();
      // do something after successful submission
    } catch (error) {
      console.error(error);
      alert("Something went wrong");
      // handle error
    }
  };
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle style={{ textAlign: "center" }}>Add HighLights</DialogTitle>
      <DialogContent>
        <form>
          <TextField
            name="title"
            label="Title"
            fullWidth
            required
            value={formData.title}
            onChange={handleInputChange}
          />
          <TextField
            name="description"
            label="Description"
            multiline
            rows={4}
            fullWidth
            required
            value={formData.description}
            onChange={handleInputChange}
          />
          <TextField
            name="link"
            label="Link"
            fullWidth
            value={formData.link}
            onChange={handleInputChange}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSubmit} color="primary">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddHighLights;
