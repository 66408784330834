import { Box, Container } from "@mui/material";
import Header from "../../components/Header";
import React, { useEffect, useState } from "react";
import { TextField, useTheme, Button } from "@material-ui/core";
import { tokens } from "../../theme";
import Highlights from "../../components/Highlights";
import Expertise from "../../components/Expertise";
import CompanySlider from "../../components/CompanySlider";
import axiosService from "../../utils/axios";
import VirtualWorldExperience from "../../components/VirtualWorldExperience";

const DigitalPowering = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [formData, setFormData] = useState({
    title: "",
    virtualUserNumber: "",
    virtualUsers: "",
    virtualBusinessNumber: "",
    virtualBusinesses: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const fetchPoweringSection = async () => {
    try {
      const response = await axiosService.get("/api/admin/powering/get");
      if (response.data) {
        setFormData(response.data);
      }
      // console.log("ReSpONSE DATA ---", response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchPoweringSection();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      title: formData.title,
      virtualUserNumber: formData.virtualUserNumber,
      virtualUsers: formData.virtualUsers,
      virtualBusinessNumber: formData.virtualBusinessNumber,
      virtualBusinesses: formData.virtualBusinesses,
    };
    console.log(payload);
    try {
      const response = await axiosService.put("/api/admin/powering", payload);
      console.log(response.data);
      alert("Submission Successfull");

      // do something after successful submission
    } catch (error) {
      console.error(error);
      alert("Something went wrong");
      // handle error
    }
  };

  return (
    <Container>
      <Box>
        <Box style={{ textAlign: "center" }}>
          <Header title="POWERING DIGITAL EXPERIENCES" />
        </Box>
        <Box
          style={{
            overflowY: "auto",
            maxHeight: "80vh",
            overflowX: "hidden",
            paddingBlock: "1rem",
          }}
        >
          <Box display="flex" flexDirection="column">
            <form
              onSubmit={handleSubmit}
              style={{
                color: "#FCFCFC",
                background: "#FCFCFC",
                paddingInline: "3rem",
                paddingBlock: "2rem",
              }}
            >
              <TextField
                name="title"
                label="Title"
                value={formData?.title}
                onChange={handleChange}
                fullWidth
                InputLabelProps={{
                  style: {
                    color: theme.palette.mode === "dark" ? "white" : undefined,
                  },
                }}
                InputProps={{
                  style: {
                    color: theme.palette.mode === "dark" ? "white" : undefined,
                  },
                }}
              />
              <TextField
                name="virtualUserNumber"
                label="Virtual User Number"
                value={formData?.virtualUserNumber}
                onChange={handleChange}
                fullWidth
                InputLabelProps={{
                  style: {
                    color: theme.palette.mode === "dark" ? "white" : undefined,
                  },
                }}
                InputProps={{
                  style: {
                    color: theme.palette.mode === "dark" ? "white" : undefined,
                  },
                }}
              />
              <TextField
                name="virtualUsers"
                label="Virtual Users"
                value={formData?.virtualUsers}
                onChange={handleChange}
                fullWidth
                InputLabelProps={{
                  style: {
                    color: theme.palette.mode === "dark" ? "white" : undefined,
                  },
                }}
                InputProps={{
                  style: {
                    color: theme.palette.mode === "dark" ? "white" : undefined,
                  },
                }}
              />
              <TextField
                name="virtualBusinessNumber"
                label="Virtual Business Number"
                value={formData?.virtualBusinessNumber}
                onChange={handleChange}
                fullWidth
                InputLabelProps={{
                  style: {
                    color: theme.palette.mode === "dark" ? "white" : undefined,
                  },
                }}
                InputProps={{
                  style: {
                    color: theme.palette.mode === "dark" ? "white" : undefined,
                  },
                }}
              />
              <TextField
                name="virtualBusinesses"
                label="Virtual Businesses"
                value={formData?.virtualBusinesses}
                onChange={handleChange}
                fullWidth
                InputLabelProps={{
                  style: {
                    color: theme.palette.mode === "dark" ? "white" : undefined,
                  },
                }}
                InputProps={{
                  style: {
                    color: theme.palette.mode === "dark" ? "white" : undefined,
                  },
                }}
              />
              <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{
                    marginTop: "1rem",
                    marginBottom: "1rem",
                    textAlign: "center",
                  }}
                >
                  Submit
                </Button>
              </Box>
            </form>
          </Box>
          <Highlights />
          <Expertise />
          <VirtualWorldExperience/>
          <CompanySlider />

        </Box>
      </Box>
    </Container>
  );
};

export default DigitalPowering;
