import React, { useEffect, useState } from "react";
import { Container } from "@material-ui/core";
import { Box, CardHeader } from "@mui/material";
import Header from "../../components/Header";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import the Quill styles
import axiosService from "./../../utils/axios";

const Legal = () => {
  const [text1, setText1] = useState("");
  const [text2, setText2] = useState("");
  const [text3, setText3] = useState("");

  const handleTextChange1 = (value) => {
    setText1(value);
  };
  const handleTextChange2 = (value) => {
    setText2(value);
  };
  const handleTextChange3 = (value) => {
    setText3(value);
  };

  const handleSubmit = async () => {
    try {
      const response = await axiosService.put("/api/admin/legal", {
        text1,
        text2,
        text3,
      });
      console.log('text .................',response.data); // Success message or response data
      alert("Submission Successfull");
    } catch (error) {
      console.error(error);
      // Handle error
      alert("Something went wrong");
    }
  };

  // Fetch legal document
  const fetchLegalDocument = async () => {
    try {
      const response = await axiosService.get("/api/admin/legal/get");
      const legalDocument = response.data;
      // Use the legal document data
      console.log(legalDocument);
      setText1(legalDocument.text1);
      setText2(legalDocument.text2);
      setText3(legalDocument.text3);
    } catch (error) {
      console.error(error);
      // Handle error
    }
  };

  useEffect(() => {
    fetchLegalDocument();
  }, []);

  return (
    <Container>
      <Box>
        <Box style={{ textAlign: "center" }}>
          <Header title="Legal" />
        </Box>
        <Box
          style={{ overflowY: "auto", maxHeight: "80vh", overflowX: "hidden" }}
        >
          {/* Privacy Policy */}
          <Box paddingY="2rem">
            <Box style={{ textAlign: "center", font: "lg" }}>
              <CardHeader title="Privacy Policy" />
            </Box>
            <ReactQuill value={text1} onChange={handleTextChange1} />
          </Box>
          {/* Job Applicant Privacy Notice. */}
          <Box paddingY="2rem">
            <Box style={{ textAlign: "center", font: "lg" }}>
              <CardHeader title="Job Applicant Privacy Notice." />
            </Box>
            <ReactQuill value={text2} onChange={handleTextChange2} />
          </Box>
          {/* Job Applicant Privacy Notice. */}
          <Box paddingY="2rem">
            <Box style={{ textAlign: "center", font: "lg" }}>
              <CardHeader title="Terms of Use" />
            </Box>
            <ReactQuill value={text3} onChange={handleTextChange3} />
          </Box>
          <Box>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <button
                onClick={handleSubmit}
                style={{
                  padding: "10px",
                  backgroundColor: "lightblue",
                  border: "none",
                  borderRadius: "5px",
                  // color: "white",
                  fontSize: "16px",
                  fontWeight: "bold",
                  cursor: "pointer",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                  transition: "background-color 0.3s ease",
                }}
              >
                Submit
              </button>
            </div>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default Legal;
