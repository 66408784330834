import { Box, Button, IconButton, useTheme } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { ColorModeContext, tokens } from "../../theme";
import InputBase from "@mui/material/InputBase";
import { MdOutlineLightMode, MdOutlineDarkMode } from "react-icons/md";
import { IoIosNotificationsOutline } from "react-icons/io";
import { FiSettings } from "react-icons/fi";
import { AiOutlineSearch } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import axiosService from "../../utils/axios";
const Topbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);

  useEffect(() => {
    const enraptureUser = JSON.parse(localStorage.getItem("enrapture-user"));

    if (!enraptureUser) {
      navigate("/login");
    }
    console.log("checking");
  }, []);

  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await axiosService.post("/api/auth/logout", null, {
        withCredentials: true, // Include cookies in the request
      });

      localStorage.setItem("enrapture-user", JSON.stringify(null));
      navigate("/login");
    } catch (error) {
      console.error("Error occurred during logout:", error);
      // Handle the error (e.g., show an error message)
    }
  };
  const handleSetting = () => {
    navigate("/setting");
  };

  return (
    <Box display="flex" justifyContent="space-between" p={2}>
      {/* SEARCH BAR */}
      <Box
        display="flex"
        backgroundColor={colors.primary[400]}
        borderRadius="3px"
      >
        <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" />
        <IconButton type="button" sx={{ p: 1 }}>
          <AiOutlineSearch />
        </IconButton>
      </Box>
      {/* ICONS */}
      <Box display="flex" gap="10px">
        <IconButton
          onClick={colorMode.toggleColorMode}
          sx={{ m: "0 10px 0 0" }}
        >
          {theme.palette.mode === "dark" ? (
            <MdOutlineDarkMode />
          ) : (
            <MdOutlineLightMode />
          )}
        </IconButton>

        {/* <IconButton>
          <IoIosNotificationsOutline />
        </IconButton> */}
        <IconButton>
          <FiSettings onClick={handleSetting} />
        </IconButton>
        <Box>
          <Button
            style={{ color: "white", backgroundColor: "black" }}
            onClick={handleLogout}
          >
            Logout
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Topbar;
