import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
} from "@material-ui/core";

const EditHighLights = ({ editOpen, setEditOpen }) => {
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    link: "",
  });

  const handleClose = () => {
    setEditOpen(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
  };

  return (
    <Dialog open={editOpen} onClose={handleClose}>
      <DialogTitle style={{ textAlign: "center" }}>Edit HighLights</DialogTitle>
      <DialogContent>
        <form>
          <TextField
            name="title"
            label="Title"
            fullWidth
            required
            value={formData.title}
            onChange={handleInputChange}
          />
          <TextField
            name="description"
            label="Description"
            multiline
            rows={4}
            fullWidth
            required
            value={formData.description}
            onChange={handleInputChange}
          />
          <TextField
            name="link"
            label="Link"
            fullWidth
            value={formData.link}
            onChange={handleInputChange}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleFormSubmit} color="primary">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditHighLights;
