import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { FiEdit } from "react-icons/fi";
import { AiFillDelete } from "react-icons/ai";
import { Box } from "@material-ui/core";
import EditData from "./FormModel/EditData";
import { imageURL } from "../utils/url";
import axiosService from "../utils/axios";

const styles = {
  card: {
    maxWidth: 345,
    marginLeft: 40,
    marginRight: 40,
  },
  media: {
    height: 140,
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: "#fff",
    border: "2px solid #000",
    boxShadow: 5,
    padding: "20px",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
};

function LeadershipCard(props) {
  const [open, setOpen] = useState(false);
  const [currentUserID, setCurrentUserID] = useState({});
  const [formData, setFormData] = useState({
    image: null,
    fullname: "",
    title: "",
    description: "",
  });
  const { classes } = props;
  const [selectedIndex, setSelectedIndex] = useState();

  const handleEdit = (data) => {
    setCurrentUserID(data._id);
    setFormData({
      fullname: data.fullname,
      title: data.title,
      description: data.description,
      image: data.image,
    });
    setOpen(true);
  };
  const handleDelete = async (teamId) => {
    console.log(teamId);
    const confirmed = window.confirm(
      "Are you sure you want to delete this item?"
    );
    if (!confirmed) {
      return;
    }
    try {
      const response = await axiosService.delete(
        `/api/admin/team/delete/${teamId}`
      );
      console.log(response.data);
      const updatedTeams = props.teams.filter((team) => team._id !== teamId);
      props.setTeams(updatedTeams);
      // handle successful deletion
    } catch (error) {
      console.error(error);
      // handle error
    }
  };
  useEffect(() => {}, [currentUserID]);

  makeStyles((theme) => ({
    paper: {
      position: "absolute",
      width: 400,
      // backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      top: "30%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
    formControl: {
      height: "100px",
      width: "358px",
      // width: "100%",
      marginTop: theme.spacing(2),
    },
    button: {
      margin: theme.spacing(1),
    },
  }));
  // props.teams.forEach((element) => {
  //   console.log(element.description.length);
  // });

  // console.log(props.teams);
  const handleNext = () => {
    if (selectedIndex < props.teams.length - 1) {
      const newTeam = [...props.teams]; // Create a copy of the array using spread operator
      let temp = newTeam[selectedIndex];
      newTeam[selectedIndex] = newTeam[selectedIndex + 1];
      newTeam[selectedIndex + 1] = temp;
      props.setTeams(newTeam);
      setSelectedIndex(selectedIndex + 1);
    }
  };

  const handlePrev = () => {
    if (selectedIndex > 0) {
      const newTeam = [...props.teams]; // Create a copy of the array using spread operator
      let temp = newTeam[selectedIndex];
      newTeam[selectedIndex] = newTeam[selectedIndex - 1];
      newTeam[selectedIndex - 1] = temp;
      props.setTeams(newTeam);
      setSelectedIndex(selectedIndex - 1);
    }
  };
  const handleSubmit = async () => {
    try {
      console.log(props.teams);
      const response = await axiosService.post(`/api/admin/team/update`, {
        teams: props.teams,
      });
      alert("Index Update Successful");
      // console.log(response.data);

      // props.setTeams(response.data);
      // handle successful deletion
    } catch (error) {
      console.error(error);
      // handle error
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        {props.teams &&
          props.teams.map((e, i) => {
            return (
              <Grid
                item
                xs={12}
                sm={4}
                style={{
                  position: "relative",
                  marginTop: "20px",
                  // overflow: "hidden",
                  // border: selectedIndex === i ? "4px solid green" : "none",
                }}
                key={e?._id}
                onClick={() => setSelectedIndex(i)}
              >
                <Box
                  style={{
                    position: "absolute",
                    display: "flex",
                    gap: "10px",
                    zIndex: "100",
                    paddingLeft: "40px",
                    right: "50px",
                    top: "215px",
                  }}
                >
                  <FiEdit
                    color="#3E4396"
                    size={24}
                    onClick={() => {
                      handleEdit(e);
                      setOpen(true);
                    }}
                    style={{ cursor: "pointer" }}
                  />
                  <AiFillDelete
                    color="red"
                    size={24}
                    onClick={() => handleDelete(e?._id)}
                    style={{ cursor: "pointer" }}
                  />
                </Box>
                <Card
                  className={classes.card}
                  style={{
                    border: selectedIndex === i ? "4px solid green" : "none",
                  }}
                >
                  <CardActionArea>
                    {e?.image ? (
                      <CardMedia
                        style={{ height: "200px" }}
                        className={classes.media}
                        image={`${imageURL}/uploads/${e?.image}`}
                        title="Contemplative Reptile"
                      />
                    ) : (
                      <div
                        style={{
                          height: "200px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <p>No Image Found</p>
                      </div>
                    )}
                    <CardContent>
                      <h2>{e?.fullname}</h2>
                      <p>
                        {e?.description?.length > 60 &&
                          e?.description?.slice(0, 60)}{" "}
                        ....
                      </p>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            );
          })}
      </Grid>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "10px",
          marginTop: "20px",
        }}
      >
        <button
          onClick={handlePrev}
          style={{
            padding: "8px 12px",
            backgroundColor: "#f1f1f1",
            border: "none",
            borderRadius: "4px",
            color: "#333",
            fontWeight: "bold",
            cursor: "pointer",
          }}
        >
          {"<"}
        </button>
        <button
          onClick={handleSubmit}
          style={{
            padding: "8px 12px",
            backgroundColor: "#007bff",
            border: "none",
            borderRadius: "4px",
            color: "#fff",
            fontWeight: "bold",
            cursor: "pointer",
          }}
        >
          Save
        </button>
        <button
          onClick={handleNext}
          style={{
            padding: "8px 12px",
            backgroundColor: "#f1f1f1",
            border: "none",
            borderRadius: "4px",
            color: "#333",
            fontWeight: "bold",
            cursor: "pointer",
          }}
        >
          {">"}
        </button>
      </div>
      <EditData
        open={open}
        setOpen={setOpen}
        currentUserID={currentUserID}
        formData={formData}
        setFormData={setFormData}
        fetchTeams={props.fetchTeams}
        props={props}
      />
    </>
  );
}

LeadershipCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LeadershipCard);
