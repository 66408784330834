import React, { useContext, useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
// import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import { AiOutlineLock } from "react-icons/ai";
import { FiUser } from "react-icons/fi";
import Logo from "../../Assets/Header Logo/Header Logo@2x.png";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "./../../context/authContext";
// const theme = createMuiTheme({
//   palette: {
//     primary: { main: "#000" },
//     secondary: { main: "#fff" },
//   },
// });

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "30px",
    backgroundColor: "#fff",
    borderradius: "4px",
    boxShadow:
      "0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)",
  },
  avatar: {
    margin: theme.spacing(2),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  signup: {
    margin: theme.spacing(-2, 0, 2),
  },
}));

export default function SignIn() {
  const classes = useStyles();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const { handleLogin } = useContext(AuthContext);

  const navigate = useNavigate();

  const handleValidation = () => {
    if (username === "") {
      setError("Username is required.");
      return false;
    } else if (password === "") {
      setError("Password is required.");
      return false;
    }
    return true;
  };

  async function handleSubmit(e) {
    e.preventDefault();
    if (handleValidation()) {
      try {
        setError("");
        // console.log(username, password);
        await handleLogin(username, password);
        navigate("/SubscribedUser");
      } catch (err) {
        setError("Failed to log in");
      }
    }
  }
  useEffect(() => {
    const enraptureUser = JSON.parse(localStorage.getItem("enrapture-user"));

    if (enraptureUser) {
      navigate("/");
    }
  }, []);

  return (
    // <MuiThemeProvider theme={theme}>
    <Container maxWidth="sm">
      <CssBaseline />
      <div className={classes.paper}>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid
            item
            xs={9}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <img
              src={Logo}
              maxWidth="300"
              alt="Logo"
              className={classes.avatar}
            />
          </Grid>
        </Grid>

        <form className={classes.form} onSubmit={handleSubmit}>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item xs={9}>
              <TextField
                className={classes.inputF}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="text"
                label="User Name"
                name="name"
                autoComplete="name"
                autoFocus
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FiUser color="black" />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={9}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" color="white">
                      <AiOutlineLock color="black" />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={9}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                className={classes.submit}
                m={0}
                style={{ backgroundColor: "black", color: "white" }}
              >
                Sign In
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
    // </MuiThemeProvider>
  );
}
