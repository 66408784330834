import React, { createContext, useEffect, useState } from 'react';
import { login } from './../services/auth.service';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(JSON.parse(localStorage.getItem("enrapture-user")) || null);

    const handleLogin = async (username, password) => {
        const user = await login(username, password);
        // console.log(username, password)
        setUser(user);
    };

    const handleLogout = () => {
        setUser(null);
    };

    useEffect(() => {
        localStorage.setItem("enrapture-user", JSON.stringify(user));
    }, [user]);
    const value = {
        user,
        handleLogin,
        handleLogout,
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
