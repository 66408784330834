import axiosService from "../utils/axios";

export const login = async (username, password) => {
    try {
        const response = await axiosService.post('/api/auth/login', { username, password });
        return response.data;
    } catch (error) {
        throw new Error(error.response.data.message);
    }
};
export const logout = () => {
    return axiosService.get(`/todos`);
};
export const getLogin = async () => {
    try {
        const response = await fetch('/api/checkLoggedIn');
        const data = await response.json();
        if (!response.ok) {
            throw new Error(data.message);
        }
        return true; // User is logged in
    } catch (error) {
        console.log(error);
        if (error.message === 'Unauthorized') {
            // const history = useHistory();
            // history.push('/login');
        }
        return false; // User is not logged in
    }
};

