import axios from "axios";

const axiosService = axios.create({
    baseURL: "https://api.enrapture.world",
    // baseURL: "http://localhost:5050",
    withCredentials: true,
});


// axiosService.interceptors.response.use(
//     (response) => {
//         // If the response is successful, just return it
//         return response;
//     },
//     (error) => {
//         // If the response has a 401 or 403 status, log out the user and redirect to the login page
//         if (error.response && [401, 403].includes(error.response.status)) {
//             logoutUser();
//         }
//         // If the response has another error status, just reject the promise with the error
//         return Promise.reject(error);
//     }
// );

// const logoutUser = async () => {
//     try {
//         await axiosService.post(`/api/auth/logout`);
//         localStorage.removeItem("enrapture-user");
//         window.location.href = "/login";
//     } catch (err) {
//         console.log(err);
//     }
// };


export default axiosService;

