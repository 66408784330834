import React, { useState } from "react";
import { Button } from "@material-ui/core";
import { AiFillDelete } from "react-icons/ai";
import { imageURL } from "./../utils/url";
import axiosService from "../utils/axios";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    gap: "20px",
    flexWrap: "wrap",
    paddingBottom: "20px",
  },
  card: {
    display: "flex",
    width: "30%",
  },
  imageContainer: {
    display: "flex",
    flexDirection: "column",
    width: "30%",
    gap: "10px",
    marginRight: "10px",
  },
  image: {
    height: "100px",
    objectFit: "cover",
  },
  contentContainer: {
    width: "70%",
    position: "relative",
  },
  mainImage: {
    height: "210px",
    width: "100%",
    objectFit: "contain",
  },
  deleteButton: {
    position: "absolute",
    top: "-5px",
    right: "0px",
    color: "red !important",
  },
}));
const InvovationCard = ({ data, setData }) => {
  const [selectedIndex, setSelectedIndex] = useState();
  const handleDelete = async (id) => {
    // Handle delete click for this card
    const confirmed = window.confirm(
      "Are you sure you want to delete this item?"
    );
    if (!confirmed) {
      return;
    }
    try {
      await axiosService.delete(`/api/admin/innovation/delete/${id}`);
      const updatedTeams = data.filter((card) => card._id !== id);
      setData(updatedTeams);
    } catch (error) {
      console.error(error);
    }
  };
  const handleNext = () => {
    if (selectedIndex < data.length - 1) {
      const newInnov = [...data];
      let temp = newInnov[selectedIndex];
      newInnov[selectedIndex] = newInnov[selectedIndex + 1];
      newInnov[selectedIndex + 1] = temp;
      setData(newInnov);
      setSelectedIndex(selectedIndex + 1);
    }
  };

  const handlePrev = () => {
    if (selectedIndex > 0) {
      const newInnov = [...data];
      let temp = newInnov[selectedIndex];
      newInnov[selectedIndex] = newInnov[selectedIndex - 1];
      newInnov[selectedIndex - 1] = temp;
      setData(newInnov);
      setSelectedIndex(selectedIndex - 1);
    }
  };
  const handleSubmit = async () => {
    try {
      const response = await axiosService.post(`/api/admin/innovation/update`, {
        innovation: data,
      });
      // console.log(response.data);
      alert("Index updated successfully");
      // props.setTeams(response.data);
      // handle successful deletion
    } catch (error) {
      console.error(error);
      // handle error
    }
  };
  const classes = useStyles();
  return (
    <>
      <div className={classes.root}>
        {data.map((card, i) => (
          <Card
            className={classes.card}
            key={i}
            style={{
              border: selectedIndex === i ? "4px solid #05f605" : "none",
            }}
            onClick={() => setSelectedIndex(i)}
          >
            <div className={classes.imageContainer}>
              {card?.sideUpperImage.includes(".mp4") ? (
                <CardMedia
                  className={classes.image}
                  component="video"
                  src={`${imageURL}/uploads/${card?.sideUpperImage}`}
                  title="Video"
                  autoPlay
                  loop
                  playsInline
                  muted
                />
              ) : (
                <CardMedia
                  className={classes.image}
                  image={`${imageURL}/uploads/${card?.sideUpperImage}`}
                  alt="img"
                />
              )}
              {card?.sideLowerImage.includes(".mp4") ? (
                <CardMedia
                  className={classes.image}
                  component="video"
                  src={`${imageURL}/uploads/${card?.sideLowerImage}`}
                  title="Video"
                  autoPlay
                  loop
                  playsInline
                  muted
                />
              ) : (
                <CardMedia
                  className={classes.image}
                  image={`${imageURL}/uploads/${card?.sideLowerImage}`}
                  alt="img"
                />
              )}
            </div>
            <div className={classes.contentContainer}>
              {card?.sideUpperImage.includes(".mp4") ? (
                <CardMedia
                  className={classes.image}
                  component="video"
                  src={`${imageURL}/uploads/${card?.rightImage}`}
                  title="Video"
                  autoPlay
                  loop
                  playsInline
                  muted
                />
              ) : (
                <CardMedia
                  className={classes.mainImage}
                  image={`${imageURL}/uploads/${card?.rightImage}`}
                  alt="img"
                />
              )}
              <Button className={classes.deleteButton}>
                <AiFillDelete
                  size={25}
                  onClick={() => handleDelete(card._id)}
                />
              </Button>
            </div>
          </Card>
        ))}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "10px",
          marginTop: "20px",
        }}
      >
        <button
          onClick={handlePrev}
          style={{
            padding: "8px 12px",
            backgroundColor: "#f1f1f1",
            border: "none",
            borderRadius: "4px",
            color: "#333",
            fontWeight: "bold",
            cursor: "pointer",
          }}
        >
          {"<"}
        </button>
        <button
          onClick={handleSubmit}
          style={{
            padding: "8px 12px",
            backgroundColor: "#007bff",
            border: "none",
            borderRadius: "4px",
            color: "#fff",
            fontWeight: "bold",
            cursor: "pointer",
          }}
        >
          Save
        </button>
        <button
          onClick={handleNext}
          style={{
            padding: "8px 12px",
            backgroundColor: "#f1f1f1",
            border: "none",
            borderRadius: "4px",
            color: "#333",
            fontWeight: "bold",
            cursor: "pointer",
          }}
        >
          {">"}
        </button>
      </div>
    </>
  );
};
export default InvovationCard;
