import React, { useContext, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/authContext";
import axiosService from "../../utils/axios";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(10),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "30px",
    backgroundColor: "#fff",
    borderradius: "4px",
    boxShadow:
      "0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)",
  },
  avatar: {
    margin: theme.spacing(2),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  signup: {
    margin: theme.spacing(-2, 0, 2),
  },
}));

const AdminSetting = () => {
  const classes = useStyles();
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [country, setCountry] = useState("");
  const [error, setError] = useState("");

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const navigate = useNavigate();

  const handleValidation = () => {
    if (username === "") {
      setError("Username is required.");
      return false;
    } else if (email === "") {
      setError("Email is required.");
      return false;
    } else if (country === "") {
      setError("Country is required.");
      return false;
    }
    return true;
  };

  async function handleSubmit(e) {
    e.preventDefault();
    // Perform password change logic here
    if (newPassword !== confirmPassword) {
      alert("New password and confirm password must match.");
      return;
    }

    // Make an API call to change the password
    axiosService
      .post("/api/auth/change-password", {
        currentPassword,
        newPassword,
      })
      .then((response) => {
        if (response.status === 200) {
          alert("Password has been changed successfully.");
          // Reset the form fields
          setCurrentPassword("");
          setNewPassword("");
          setConfirmPassword("");
        } else {
          throw new Error("Failed to change password.");
        }
      })
      .catch((error) => {
        console.error(error);
        alert("Failed to change password. Please try again.");
      });
  }

  return (
    <Container maxWidth="sm">
      <div className={classes.paper}>
        <Grid>
          <h1 style={{ color: "black" }}>Change Password</h1>
        </Grid>

        <form className={classes.form} onSubmit={handleSubmit}>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item xs={9}>
              <TextField
                className={classes.inputF}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="text"
                label="Current Password"
                name="currentPassword"
                autoComplete="current-password" // Use "current-password" for better browser support
                autoFocus
                type="password" // Set the input type to "password"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
              />
            </Grid>

            <Grid item xs={9}>
              <TextField
                className={classes.inputF}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="newPassowrd"
                label="New Password"
                name="newPassowrd"
                autoComplete="newPassowrd"
                autoFocus
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </Grid>

            <Grid item xs={9}>
              <TextField
                className={classes.inputF}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="confirmPassword"
                label="Confirm Password"
                name="confirmPassword"
                autoComplete="confirmPassword"
                type="password"
                autoFocus
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </Grid>

            <Grid item xs={9}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                className={classes.submit}
                m={0}
                style={{ backgroundColor: "black", color: "white" }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
};
export default AdminSetting;
