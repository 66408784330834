import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { TextField, useTheme, Button } from "@material-ui/core";
import Header from "./Header";
import axiosService from "../utils/axios";

const VirtualWorldExperience = () => {
  const theme = useTheme();

  const [formData, setFormData] = useState({
    title: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      title: formData.title,
    };

    try {
      const response = await axiosService.put("/api/admin/virtual", payload);
      console.log(response.data);
      // Handle the response data or perform any further actions as needed

      // Reset the form data
      alert("Updated Successfully");
    } catch (error) {
      console.error(error);
      // Handle the error
    }
  };

  const fetchVirtualWorldSection = async () => {
    try {
      const response = await axiosService.get("/api/admin/virtual/get");
      if (response.data) {
        setFormData(response.data);
      }
      // console.log("ReSpONSE DATA ---", response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchVirtualWorldSection();
  }, []);
  return (
    <Box style={{ marginBlock: "2rem" }}>
      <Box style={{ textAlign: "center" }}>
        <Header title="Virtual World Experience:" />
      </Box>
      <Box display="flex" flexDirection="column">
        <form
          onSubmit={handleSubmit}
          style={{
            color: "#FCFCFC",
            background: "#FCFCFC",
            paddingInline: "2rem",
            paddingBlock: "1rem",
          }}
        >
          <TextField
            name="title"
            label="Title"
            value={formData?.title}
            onChange={handleChange}
            fullWidth
          />
          <Box style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{
                marginTop: "1rem",
                marginBottom: "1rem",
                textAlign: "center",
              }}
            >
              Submit
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default VirtualWorldExperience;
